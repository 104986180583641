import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import Close from '../assets/Icons/Close'

dayjs.extend(duration)
dayjs.extend(utc)
dayjs.extend(timezone)

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 90;
`

const ModalContent = styled.div`
  position: relative;
  background: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  text-align: center;
`

const CloseIconWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`

const Title = styled.h2`
  font-weight: 300;
  margin-bottom: 2.1rem;
  font-size: 2rem;
  line-height: 2.3rem;
  @media ${(props) => props.theme.breakpoints.md} {
    font-size: 3.6rem;
    line-height: 4.2rem;
  }
`

const StyledParagraph = styled.p`
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 2.5rem;
`

const CountdownContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
`

const TimeSegment = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
`

const TimeValue = styled.div`
  font-size: 5rem;
  font-weight: 500;
  width: 6rem;
  text-align: center;
`

const TimeLabel = styled.div`
  font-size: 1rem;
  font-weight: 300;
  color: #888;
`

interface EventCountdownModalProps {
  onClose: () => void
  eventStart: dayjs.Dayjs
  eventEnd: dayjs.Dayjs
}

const eventIsLive = (eventStart: dayjs.Dayjs, eventEnd: dayjs.Dayjs) => {
  return (
    dayjs.tz(dayjs(), 'Europe/Vienna').isAfter(eventStart) &&
    dayjs.tz(dayjs(), 'Europe/Vienna').isBefore(eventEnd)
  )
}

const EventCountdownModal: React.FC<EventCountdownModalProps> = ({
  onClose,
  eventStart,
  eventEnd,
}) => {
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 })
  const [isLive, setIsLive] = useState(eventIsLive(eventStart, eventEnd))
  useEffect(() => {
    const interval = setInterval(() => {
      const now = dayjs.tz(dayjs(), 'Europe/Vienna')
      const diff = eventStart.diff(now)

      if (diff <= 0) {
        clearInterval(interval)
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 })
        setIsLive(eventIsLive(eventStart, eventEnd))
      } else {
        const totalDays = eventStart.diff(now, 'days')
        const remainingAfterDays = eventStart.subtract(totalDays, 'days')
        const hours = remainingAfterDays.diff(now, 'hours')
        const remainingAfterHours = remainingAfterDays.subtract(hours, 'hours')
        const minutes = remainingAfterHours.diff(now, 'minutes')
        const remainingAfterMinutes = remainingAfterHours.subtract(minutes, 'minutes')
        const seconds = remainingAfterMinutes.diff(now, 'seconds')

        setTimeLeft({ days: totalDays, hours, minutes, seconds })
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return ReactDOM.createPortal(
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseIconWrapper onClick={onClose}>
          <Close />
        </CloseIconWrapper>
        {!isLive ? (
          <>
            <Title>Event Countdown</Title>
            <StyledParagraph>
              Du nimmst am Event am 12. Dezember teil. Wir streamen live für dich ab 08:00 Uhr.
            </StyledParagraph>
            <CountdownContainer>
              <TimeSegment>
                <TimeValue>{String(timeLeft.days).padStart(2, '0')}</TimeValue>
                <TimeLabel>Tage</TimeLabel>
              </TimeSegment>
              <TimeSegment>
                <TimeValue>{String(timeLeft.hours).padStart(2, '0')}</TimeValue>
                <TimeLabel>Stunden</TimeLabel>
              </TimeSegment>
              <TimeSegment>
                <TimeValue>{String(timeLeft.minutes).padStart(2, '0')}</TimeValue>
                <TimeLabel>Minuten</TimeLabel>
              </TimeSegment>
              <TimeSegment>
                <TimeValue>{String(timeLeft.seconds).padStart(2, '0')}</TimeValue>
                <TimeLabel>Sekunden</TimeLabel>
              </TimeSegment>
            </CountdownContainer>
          </>
        ) : (
          <>
            <Title>Live Event</Title>
            <StyledParagraph>
              Wir streamen live für dich. Klick einfach auf den Streaming Button unten und nimm an
              unserem Event teil.
            </StyledParagraph>
          </>
        )}
      </ModalContent>
    </ModalOverlay>,
    document.body
  )
}

export default EventCountdownModal
